<template>
  <div
    v-if="brandList.length"
    class="d-inline-flex">
    <span
      v-for="(brand, index) in brandList"
      :key="index">
      <v-checkbox
        :input-value="currentBrandsId.includes(brand.id)"
        :label="brand.name"
        class="mx-3"
        value
        hide-details
        @change="(checked) => onChange(brand.id, checked)"
      />
    </span>
  </div>
</template>

<script>

export default {
  props: {
    brandsId: {
      type: Array,
      default: () => []
    },
    brandList: {
      type: Array,
      default: () => []
    },
    onChangeBrands: {
      type: Function,
      default: () => ''
    }
  },
  data () {
    return {
      currentBrandsId: []
    }
  },
  async beforeMount () {
    this.currentBrandsId = this.$props.brandsId || []
  },
  methods: {
    onChange (brandId, checked) {
      if (checked) {
        this.currentBrandsId = [
          ...this.currentBrandsId,
          brandId
        ]
      } else {
        this.currentBrandsId = this.currentBrandsId.filter(id => id !== brandId)
      }

      this.$props.onChangeBrands(this.currentBrandsId)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
