<template>
  <a
    v-if="invoice.pdf && invoice.pdf.file"
    :href="invoice.pdf.file.url"
    target="_blank"
    @click.stop="(event) => event.stopPropagation()"
  >
    <v-icon v-if="linkIcon">
      file_copy
    </v-icon>
    <span v-else>{{ invoice.pdf.file.name }}</span>
  </a>
  <div
    v-else-if="invoice.pdf.error"
    class="d-flex align-items-center">
    <v-menu
      bottom
      offset-y
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          class="ma-2 white--text"
          small
          color="error"
          v-on="on"
        >
          {{ invoice.pdf.status }}
        </v-btn>
      </template>
      <v-card
        class="pa-3"
        max-width="300">
        {{ invoice.pdf.error }}
      </v-card>
    </v-menu>
  </div>
  <b v-else>{{ invoice.pdf && invoice.pdf.status }}</b>
</template>

<script>
import Request from '../../helpers/request'

export default {
  mixins: [Request],
  props: {
    invoice: {
      type: Object,
      default: () => {}
    },
    linkIcon: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      loading: false
    }
  }
}

</script>
