<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div>
    <v-container
      grid-list-xl>
      <v-layout
        fluid
        pt-0
        wrap>
        <v-flex xs12>
          <app-card
            :heading="heading || endpoint"
            :footer="true"
            col-classes="xl12 lg12 md12 sm12 xs12"
            custom-classes="mb-0"
          >
            <div>
              <v-layout
                class="align-center filter-holder justify-space-between"
                wrap>
                <brands-filter
                  :brands-id="brandsFilter"
                  :brand-list="brandList"
                  :on-change-brands="onChangeBrands"
                />
                <v-flex
                  sm6
                  xs12>
                  <v-layout :class="`align-center ${showBrandsFilter ? 'justify-end' : 'justify-start'}`">
                    <v-flex
                      v-if="!isDisabledDeletedFilter"
                      xs3>
                      <v-checkbox
                        v-model="isDeleted"
                        :label="`Deleted`"
                        hide-details
                      />
                    </v-flex>
                    <v-flex
                      v-if="showRidesToInvoiceFilter"
                      xs5>
                      <v-checkbox
                        v-model="hasRidesToInvoice"
                        :label="`Has rides to invoice`"
                        hide-details
                      />
                    </v-flex>
                    <v-flex
                      v-if="showSentFilter"
                      xs3>
                      <v-checkbox
                        v-model="isSentInvoice"
                        :label="`Sent`"
                        hide-details
                      />
                    </v-flex>
                    <v-flex
                      v-if="showSyncFilter"
                      xs3>
                      <v-checkbox
                        v-model="isSyncInvoice"
                        :label="`Synced`"
                        hide-details
                      />
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex
                  v-if="showSearchFilter"
                  lg4
                  md4
                  sm6
                  xs12>
                  <v-text-field
                    v-model="searchTerm"
                    outline
                    hide-details
                    label="Search"
                    @input="handleSearchTerm"
                  />
                </v-flex>
                <v-flex
                  v-if="regionList.length"
                  lg4
                  md4
                  sm6
                  xs12>
                  <v-autocomplete
                    v-model="regionsFilter"
                    :items="regionList"
                    :item-text="(val) => { return `${val.name} id: ${val.id}`}"
                    multiple
                    outline
                    hide-details
                    label="Region"
                    item-value="id"
                  />
                </v-flex>
                <v-flex
                  v-if="showRidesToInvoiceFilter"
                  lg4
                  md4
                  sm6
                  xs12>
                  <v-select
                    :items="Object.values(invoicingTypeDictionary)"
                    v-model="invoiceTypeFilter"
                    item-text="text"
                    outline
                    clearable
                    hide-details
                    label="Invoice period"
                    item-value="value"
                  />
                </v-flex>
                <v-flex
                  v-if="csvData.length"
                  lg2
                  md3
                  xs6>
                  <v-btn
                    color="secondary"
                    class="mr-3"
                    @click="exportCSV">Export CSV</v-btn>
                </v-flex>
                <v-btn
                  v-if="showDriverAvailabilityFilter"
                  :disabled="brandsFilter.length !== 1"
                  color="secondary"
                  class="mr-3"
                  @click="toggleDriverAvailabilityFilter">

                  Driver availability
                </v-btn>
                <v-btn
                  v-if="showCreate"
                  color="primary"
                  class="mr-3 create-btn"
                  @click="$emit('create')"
                >Create</v-btn>
              </v-layout>

              <v-data-table
                :headers="headers"
                :items="items"
                :loading="loading"
                :pagination.sync="pagination"
                :total-items="totalItems"
                :rows-per-page-items="[10, 25, 100, 500]"
                class="elevation-1"
              >
                <template v-slot:items="props">
                  <td class="py-1 crud-table-td" v-if="isInvoices">
                    <div>
                      <v-icon v-if="props.item.sentAt" color="green">mail</v-icon>
                      <v-icon v-else color="red">mail_outline</v-icon>
                    </div>
                  </td>
                  <slot v-if="customTemplate" name="items" v-bind:item="props.item"/>
                  <td
                    v-else
                    v-for="(item, index) in listItems"
                    :key="index"
                    class="py-1 px-0 crud-table-td"
                  >
                    <a v-if="item.value != null || item.custom"
                      :href="rowClickPath && `${rowClickPath}/${props.item.id}${rowClickQueryString}`"
                      :target="isRowClickNewTab ? '_blank' : '_self'"
                      class="custom-row-link crud-table-row">
                      <div v-if="item.custom">
                        <div v-html="item.custom(props.item)"></div>
                      </div>
                      <div v-else-if="typeof props.item[item.value] === 'object' && item.objectKey">
                        <span
                          v-for="(objectElem, ii) in props.item[item.value]"
                          :key="ii">
                          {{ objectElem[item.objectKey] }}
                        </span>
                      </div>
                      <div v-else-if="typeof props.item[item.value] === 'array'">
                        <span
                          v-for="(arrElem, ii) in props.item[item.value]"
                          :key="ii">
                          {{ arrElem }}
                        </span>
                      </div>
                      <div v-else-if="typeof props.item[item.value] === 'boolean'">
                        {{ props.item[item.value] ? 'Yes' : 'No' }}
                      </div>
                      <div v-else-if="typeof props.item[item.value] !== 'number' && gFunc.isValidDate(props.item[item.value])">
                        {{ gFunc.momentDate(props.item[item.value]) }} -  {{ gFunc.momentTime(props.item[item.value]) }}
                      </div>
                      <div v-else-if="item.image != null && props.item[item.value] != null">
                        <img
                          :src="props.item[item.value][item.textValue]"
                          :alt="props.item[item.value].originalName"
                          width="50">
                      </div>
                      <div v-else-if="item.pdf && props.item[item.value] != null">
                        <invoice-pdf
                          :invoice="props.item"
                          :link-icon="item.pdfIcon"
                        />
                      </div>
                      <div v-else-if="item.link && props.item[item.value] != null">
                        <a
                          :href="props.item[item.value][item.textValue]"
                          :target="item.linkNewTab ? '_blank' : '_self'"
                          @click.stop="(event) => event.stopPropagation()" >{{ item.text }}</a>
                      </div>
                      <div v-else-if="item.insideLink && props.item[item.value] && props.item[item.value][item.textValue]">
                        <a
                          :href="item.insideLink.href + props.item[item.value][item.textValue]"
                          :target="item.linkNewTab && '_blank'"
                          @click.stop="(event) => event.stopPropagation()" >{{ item.insideLink.textValue ? props.item[item.value][item.insideLink.textValue] : item.text }}</a>
                      </div>
                      <div v-else>
                        {{ item.textValue != null && props.item[item.value] != null ? props.item[item.value][item.textValue] : props.item[item.value] }}
                      </div>
                    </a>
                  </td>
                  <td>
                    <div class="row-layout">
                      <v-btn
                        v-if="isEditable"
                        color="primary"
                        @click="$emit('edit', props.item)">Edit</v-btn>
                      <v-btn
                        v-if="isDeleted && isRestorable"
                        color="warning"
                        class="mr-3"
                        @click="$emit('restore', props.item.id)"
                      >Restore</v-btn>
                      <v-btn
                        v-else-if="isDeleteble && !isDeleted"
                        color="warning"
                        class="mr-3"
                        @click="$emit('delete', props.item.id)"
                      >Delete</v-btn>
                      <v-btn
                        v-if="isSync(props.item)"
                        :loading="syncLoading"
                        color="primary"
                        class="mr-3"
                        @click="syncFunction(props.item.id)"
                      >{{ syncTitle }}</v-btn>
                    </div>
                  </td>
                </template>
              </v-data-table>
            </div>
          </app-card>
        </v-flex>
        <v-dialog
          v-model="driverAvailabilityFilter.show"
          max-width="700px">
          <v-card>
            <div class="field-wrapper">
              <div class="custom-label black--text mb-3">Availability</div>
              <v-layout wrap>
                <v-flex>
                  <v-date-picker
                    ref="picker"
                    v-model="driverAvailabilityFilter.day"
                    :first-day-of-week="1"
                    full-width
                  />
                  <div class="items-center justify-start">
                    <v-radio-group
                      :disabled="!driverAvailabilityFilter.day"
                      v-model="driverAvailabilityFilter.type"
                      row
                      hide-details
                    >
                      <v-radio
                        label="Daytime"
                        value="daytime"
                      />
                      <v-radio
                        label="Evening"
                        value="evening"
                      />
                    </v-radio-group>
                  </div>
                  <v-card-actions>
                    <v-spacer/>
                    <v-btn
                      color="secondary"
                      @click="acceptDriverAvailabilityFilter">Accept</v-btn>

                    <v-btn
                      color="secondary"
                      flat
                      @click="clearDriverAvailabilityFilter">Clear</v-btn>
                  </v-card-actions>
                </v-flex>
              </v-layout>
            </div>
          </v-card>
        </v-dialog>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import Request from '../../helpers/request'
import BrandsFilter from 'Components/BrandsFilter'
import csvExport from '../../helpers/csvExport'
import _debounce from 'lodash/debounce'
import lodash from 'lodash'
import InvoicePdf from 'Components/Invoice/InvoicePdf'

export default {
  components: { BrandsFilter, InvoicePdf },
  mixins: [Request],
  props: {
    isSync: {
      type: Function,
      default: (invoice) => { return false }
    },
    syncTitle: {
      type: String,
      default: 'Sync'
    },
    isInvoices: {
      type: Boolean,
      default: false
    },
    isDeleteble: {
      type: Boolean,
      default: true
    },
    isRestorable: {
      type: Boolean,
      default: true
    },
    endpoint: {
      type: String,
      default: ''
    },
    heading: {
      type: String,
      default: ''
    },
    listItems: {
      type: Array,
      default: () => { return [] }
    },
    brandList: {
      type: Array,
      default: () => { return [] }
    },
    regionList: {
      type: Array,
      default: () => { return [] }
    },
    rowClickPath: {
      type: String,
      default: ''
    },
    isRowClickNewTab: {
      type: Boolean,
      default: false
    },
    showSentFilter: {
      type: Boolean,
      default: false
    },
    showSyncFilter: {
      type: Boolean,
      default: false
    },
    showBrandsFilter: {
      type: Boolean,
      default: false
    },
    showSearchFilter: {
      type: Boolean,
      default: false
    },
    showRidesToInvoiceFilter: {
      type: Boolean,
      default: false
    },
    hiddenRidesToInvoiceFilter: {
      type: Boolean,
      default: false
    },
    isEditable: {
      type: Boolean,
      default: true
    },
    isDisabledDeletedFilter: {
      type: Boolean,
      default: false
    },
    queryString: {
      type: String,
      default: ''
    },
    rowClickQueryString: {
      type: String,
      default: ''
    },
    csvTitle: {
      type: String,
      default: ''
    },
    csvData: {
      type: Array,
      default: () => []
    },
    showCreate: {
      type: Boolean,
      default: true
    },
    showDriverAvailabilityFilter: {
      type: Boolean,
      default: false
    },
    hasRidesToInvoice: {
      type: Boolean,
      default: false
    },
    customTemplate: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      loading: true,
      syncLoading: false,
      headers: [

      ],
      driverAvailabilityFilter: {
        show: false,
        type: '',
        day: ''
      },
      items: [],
      pagination: {
        rowsPerPage: 100,
        page: 1
      },
      totalItems: 0,
      isDeleted: false,
      isSyncInvoice: false,
      isSentInvoice: false,
      brandsFilter: null,
      regionsFilter: null,
      searchTerm: null,
      invoiceTypeFilter: null,
      invoicingTypeDictionary: {
        scheduled: {
          text: 'Each ride',
          value: 'per_ride'
        },
        active: {
          text: 'Weekly',
          value: 'weekly'
        },
        archive: {
          text: 'Monthly',
          value: 'monthly'
        }
      },
      filterProps: {}
    }
  },
  computed: {
    changeFilters () {
      const { isDeleted, isSentInvoice, isSyncInvoice, hasRidesToInvoice, brandsFilter, regionsFilter, invoiceTypeFilter } = this
      return { isDeleted, isSentInvoice, isSyncInvoice, hasRidesToInvoice, brandsFilter, regionsFilter, invoiceTypeFilter }
    }
  },
  watch: {
    pagination: {
      deep: true,
      handler (newValue, oldValue) {
        if (
          oldValue.page !== newValue.page ||
          oldValue.rowsPerPage !== newValue.rowsPerPage ||
          (oldValue.sortBy ? oldValue.sortBy !== newValue.sortBy : false) ||
          (oldValue.hasOwnProperty('descending') ? oldValue.descending !== newValue.descending : false)
        ) {
          const query = {
            sort: newValue.sortBy,
            sortDir: newValue.descending ? 'desc' : 'asc'
          }

          this.requestData({ ...this.filterProps, ...query })
        }
      }
    },
    changeFilters: {
      deep: true,
      handler () {
        let updatedRouteQuery = { ...this.$route.query }

        let filterProps = {
          deleted: this.isDeleted ? 1 : 0,
          sentAt: this.isSentInvoice ? 1 : 0,
          synced: this.isSyncInvoice ? 1 : 0,
          hasRidesToInvoice: (this.showRidesToInvoiceFilter || this.hiddenRidesToInvoiceFilter) && this.hasRidesToInvoice ? 1 : undefined,
          brands: this.brandsFilter && this.brandsFilter.length ? this.brandsFilter.join(',') : undefined,
          regions: this.regionsFilter && this.regionsFilter.length ? this.regionsFilter.join(',') : undefined,
          invoicingType: this.invoiceTypeFilter && this.invoiceTypeFilter.length ? this.invoiceTypeFilter : undefined,
          search: this.searchTerm ? this.searchTerm : undefined
        }

        this.filterProps = filterProps

        if (this.brandsFilter && this.brandsFilter.length) {
          updatedRouteQuery = { ...updatedRouteQuery, brands: this.brandsFilter.join(',') }
        } else {
          delete updatedRouteQuery.brands
        }

        this.$router.replace({ query: updatedRouteQuery }).catch(err => err)

        this.requestData(filterProps)
      }
    }
  },
  beforeMount () {
    this.headers = lodash.cloneDeep(this.listItems)
    if (this.isInvoices) {
      this.headers.unshift({ text: '', sortable: false, value: null })
    }
    this.loadFilters()
    this.requestData()
  },
  methods: {
    syncFunction (id) {
      try {
        this.syncLoading = true
        this.$emit('sync', id, () => {
          this.syncLoading = false
        })
      } catch (error) {
      }
    },
    loadFilters () {
      if (this.$route.query.brands) {
        this.brandsFilter = this.$route.query.brands.split(',').map(brand => parseInt(brand))
      } else {
        const brands = this.brandList.map(brand => brand.id)
        this.brandsFilter = brands
      }
    },
    async acceptDriverAvailabilityFilter () {
      try {
        let filterProps = { deleted: this.isDeleted ? 1 : 0,
          hasRidesToInvoice: this.hasRidesToInvoice ? 1 : undefined,
          availableDay: this.driverAvailabilityFilter.day,
          availableType: this.driverAvailabilityFilter.type }

        if (this.regionsFilter) {
          filterProps = {
            ...filterProps,
            regions: this.regionsFilter && this.regionsFilter.length ? this.regionsFilter.join(',') : undefined
          }
        }

        if (this.invoiceTypeFilter) {
          filterProps = {
            ...filterProps,
            invoicingType: this.invoiceTypeFilter && this.invoiceTypeFilter.length ? this.invoiceTypeFilter : undefined
          }
        }

        if (this.brandsFilter) {
          filterProps = {
            ...filterProps,
            brands: this.brandsFilter && this.brandsFilter.length ? this.brandsFilter.join(',') : undefined
          }
        }

        if (this.searchTerm) {
          filterProps = {
            ...filterProps,
            search: this.searchTerm
          }
        }

        this.requestData(filterProps)
        this.driverAvailabilityFilter.show = false
      } catch (error) {
        this.driverAvailabilityFilter.show = false
      }
    },
    async clearDriverAvailabilityFilter () {
      this.driverAvailabilityFilter.day = ''
      this.driverAvailabilityFilter.type = ''

      try {
        let filterProps = { deleted: this.isDeleted ? 1 : 0,
          hasRidesToInvoice: this.hasRidesToInvoice ? 1 : undefined }

        if (this.regionsFilter) {
          filterProps = {
            ...filterProps,
            regions: this.regionsFilter && this.regionsFilter.length ? this.regionsFilter.join(',') : undefined
          }
        }

        if (this.invoiceTypeFilter) {
          filterProps = {
            ...filterProps,
            invoicingType: this.invoiceTypeFilter && this.invoiceTypeFilter.length ? this.invoiceTypeFilter : undefined
          }
        }

        if (this.brandsFilter) {
          filterProps = {
            ...filterProps,
            brands: this.brandsFilter && this.brandsFilter.length ? this.brandsFilter.join(',') : undefined
          }
        }

        if (this.searchTerm) {
          filterProps = {
            ...filterProps,
            search: this.searchTerm
          }
        }

        this.requestData(filterProps)
        this.driverAvailabilityFilter.show = false
      } catch (error) {
        this.driverAvailabilityFilter.show = false
      }
    },
    toggleDriverAvailabilityFilter () {
      this.driverAvailabilityFilter.show = !this.driverAvailabilityFilter.show
    },
    exportCSV () {
      csvExport(this.$props.csvTitle, this.$props.csvData)
    },
    onChangeBrands (brandsIds) {
      this.brandsFilter = brandsIds
    },
    requestData (query) {
      const baseUrl = this.queryString ? `/${this.endpoint}?pageSize=${this.pagination.rowsPerPage === -1 ? 999 : this.pagination.rowsPerPage}&page=${this.pagination.page}${this.queryString}` : `/${this.endpoint}?pageSize=${this.pagination.rowsPerPage === -1 ? 999 : this.pagination.rowsPerPage}&page=${this.pagination.page}`

      let body = query ? { params: query } : {}
      this.request('GET', baseUrl, body, ({ data }) => {
        this.items = data.data || data
        this.$emit('getData', data.data || data)
        this.totalItems = data.total
      }, (loader) => { this.loading = loader })
    },
    handleSearchTerm: _debounce(
      function () {
        let filterProps = {
          regions: this.regionsFilter && this.regionsFilter.length ? this.regionsFilter.join(',') : undefined,
          invoicingType: this.invoiceTypeFilter && this.invoiceTypeFilter.length ? this.invoiceTypeFilter : undefined,
          brands: this.brandsFilter && this.brandsFilter.length ? this.brandsFilter.join(',') : undefined,
          deleted: this.isDeleted ? 1 : 0,
          hasRidesToInvoice: this.hasRidesToInvoice ? 1 : undefined,
          search: this.searchTerm !== '' ? this.searchTerm : undefined
        }

        if (this.showDriverAvailabilityFilter && this.brandsFilter.length === 1 && this.driverAvailabilityFilter.day &&
          this.driverAvailabilityFilter.type) {
          filterProps = {
            ...filterProps,
            availableDay: this.driverAvailabilityFilter.day,
            availableType: this.driverAvailabilityFilter.type
          }
        }

        this.filterProps = filterProps

        this.requestData(filterProps)
      }, 1500)
  }
}
</script>

<style lang="scss" scoped>
  .create-btn {
    margin-left: auto;
  }

  .field-wrapper {
    padding: 30px;
  }

  .custom-row-link {
    width: 100%;
    height: 100%;
    display: flex;
    color: inherit;
    align-items: center;
    padding: 0 24px;
  }

  .crud-table-td {
    height: auto;
  }

  .crud-table-row {
    max-height: 120px;
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
</style>
